import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            A wonderful, inclusive environment where understanding grows wild. As an autism mom, it struck me when their website said, "a place where you don't have to say I'm sorry." That really meant a lot to me          </p>
                            <p className="overview">T. F.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            I have hosted my daughter's birthday party here for the past 2 years and I just love it. I'm so grateful that we're able to have a space where we can celebrate my daughter in a comfortable environment given her health. The owners are nice, wonderful people and I've always gotten a welcoming feeling from them. Their space is always clean and inviting. I would definitely recommend them to anyone who's interest.                            </p>
                            <p className="overview">Jazzmen McClain </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            We recently celebrated our daughters first birthday here and it was the best decision by far. Their par packages were perfect and made things so much easier. Everyone had a great time. We visit this location often and truly enjoy how clean it is. Thank you!                             </p>
                            <p className="overview">
                            Ashley McCray</p>
                        </div>                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
